import {
    CREATE_ORG,
    GET_ALL_ORGS,
    UPDATE_ORG,
    DELETE_ORG,
    GET_ORG_BY_ID,
    GET_ALL_SITES_BY_ORG_ID,
    ADD_SITE_TO_ORG,
    GET_ALL_USERS_BY_ORG_ID,
    CLEAR_ORG_USER_LIST,
    CLEAR_ORG,
    API_ERROR,
    API_START,
    API_END
} from '../actions/OrgActions';

const initialState = {
    orgsList: [],
    orgSiteList: [],
    org: {},
    orgUsersList: [],
    loading: false,
    error: null,
};

const orgReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_ORG:
            return {
                ...state,
                org: { ...action.payload },
            };
        case GET_ALL_ORGS:
            return {
                ...state,
                orgsList: action.payload,
            };
        case UPDATE_ORG:
            const updatedOrgs = state.orgsList.map((org) =>
                org.id === action.payload.orgId ? action.payload.updatedOrgData : org
            );
            return {
                ...state,
                orgsList: updatedOrgs,
            };
        case DELETE_ORG:
            const remainingOrgs = state.orgsList.filter((org) => org.id !== action.payload);
            return {
                ...state,
                orgsList: remainingOrgs,
            };
        case GET_ORG_BY_ID:
            return {
                ...state,
                org: action.payload,
            };
        case GET_ALL_SITES_BY_ORG_ID:
            return {
                ...state,
                orgSiteList: action.payload,
            };
        case ADD_SITE_TO_ORG:
            return {
                ...state,
                orgSiteList: [...state.orgSiteList, action.payload]
            };
        case API_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case API_END:
            return {
                ...state,
                loading: false,
            };
        case API_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case GET_ALL_USERS_BY_ORG_ID:
            return {
                ...state,
                orgUsersList: action.payload,
            };
        case CLEAR_ORG_USER_LIST:
            return {
                ...state,
                orgUsersList: [],
            };
        case CLEAR_ORG:
            return {
                ...state,
                org: {},
            };
        default:
            return state;
    }
};

export default orgReducer;
