import React, { lazy } from 'react';
import Loadable from "app/components/Loadable";

const UserProfile = Loadable(lazy(() => import("./UserProfile")));
const ManageAccount = Loadable(lazy(() => import("./ManageAccount")));
const userRoutes = [
    {
        path: '/user/profile',
        element: <UserProfile />,
    },
    {
        path: '/user/manage-account',
        element: <ManageAccount />,
    }
]

export default userRoutes