import {
    GET_ALL_SITES,
    GET_SITE,
    GET_SITE_CARDS,
    GET_SITE_USERS,
    CREATE_SITE,
    CLEAR_SITE,
    UPDATE_SITE,
    DELETE_SITE,
    INVITE_USER_TO_SITE,
    UPDATE_SITE_IMAGE,
    API_START,
    API_END,
    API_ERROR,
    UNAUTHORIZED_ACCESS // Add this line
} from '../actions/SiteActions';

const initialState = {
    site: {},
    siteList: [],
    siteCardsList: [],
    siteUsersList: [],
    message: '',
    uploadInfo: {},
    loading: false,
    error: null,
    unauthorized: false, // Add this line
};

const SiteReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_SITES:
            return {
                ...state,
                siteList: action.payload,
            };
        case GET_SITE:
            return {
                ...state,
                site: action.payload,
                unauthorized: false, // Reset unauthorized state on successful fetch
            };
        case CLEAR_SITE:
            return {
                ...state,
                site: {},
                unauthorized: false, // Reset unauthorized state on clear
            };
        case GET_SITE_CARDS:
            return {
                ...state,
                siteCardsList: action.payload,
            };
        case GET_SITE_USERS:
            return {
                ...state,
                siteUsersList: action.payload,
            };
        case CREATE_SITE:
            return {
                ...state,
                message: action.payload,
            };
        case UPDATE_SITE:
            return {
                ...state,
                message: action.payload,
            };
        case DELETE_SITE:
            return {
                ...state,
                message: action.payload,
            };
        case INVITE_USER_TO_SITE:
            return {
                ...state,
                snackbar: action.payload,
            };
        case UPDATE_SITE_IMAGE:
            return {
                ...state,
                uploadInfo: action.payload,
            };
        case API_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case API_END:
            return {
                ...state,
                loading: false,
            };
        case API_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case UNAUTHORIZED_ACCESS: // Add this case
            return {
                ...state,
                unauthorized: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default SiteReducer;
