import axios from 'axios';
import { setSnackbar } from './SnackbarActions';
import { getAllSitesByOrgId } from './OrgActions';

const apiConfig = {
    domain: process.env.REACT_APP_API_DOMAIN,
    version: process.env.REACT_APP_API_VERSION
};

export const GET_ALL_SITES = 'GET_ALL_SITES';
export const GET_SITE = 'GET_SITE';
export const GET_SITE_CARDS = 'GET_SITE_CARDS';
export const GET_SITE_USERS = 'GET_SITE_USERS';
export const CLEAR_SITE = 'CLEAR_SITE';
export const CREATE_SITE = 'CREATE_SITE';
export const UPDATE_SITE = 'UPDATE_SITE';
export const DELETE_SITE = 'DELETE_SITE';
export const UPLOAD_FILES = 'UPLOAD_FILES';
export const INVITE_USER_TO_SITE = 'INVITE_USER_TO_SITE';
export const UPDATE_SITE_IMAGE = 'UPDATE_SITE_IMAGE';
export const API_START = 'API_START';
export const API_END = 'API_END';
export const API_ERROR = 'API_ERROR';
export const UNAUTHORIZED_ACCESS = 'UNAUTHORIZED_ACCESS';

const apiStart = () => ({ type: API_START });
const apiEnd = () => ({ type: API_END });
const apiError = (error) => ({ type: API_ERROR, payload: error });

const handleApiResponse = (res, dispatch, successAction, successMessage, errorMessage) => {
    if (res.status === 200) {
        dispatch(successAction);
        if (successMessage) {
            dispatch(setSnackbar('success', successMessage));
        }
    } else {
        dispatch(setSnackbar('error', errorMessage || `Received status code ${res.status}`));
        console.error(`Received status code ${res.status}`);
    }
};

export const getSiteList = () => async (dispatch) => {
    dispatch(apiStart());
    try {
        const res = await axios.get(`${apiConfig.domain}${apiConfig.version}/GetSites`);
        dispatch({ type: GET_ALL_SITES, payload: res.data });
    } catch (err) {
        dispatch(setSnackbar('error', 'Unable to retrieve Projects'));
        dispatch(apiError(err.message));
    } finally {
        dispatch(apiEnd());
    }
};

export const getSite = (siteId) => async (dispatch) => {
    dispatch(apiStart());
    try {
        const res = await axios.get(`${apiConfig.domain}${apiConfig.version}/GetSiteById`, { params: { SiteId: siteId } });
        dispatch({ type: GET_SITE, payload: res.data });
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch({ type: UNAUTHORIZED_ACCESS });
        } else {
            dispatch(setSnackbar('error', 'Unable to retrieve site'));
        }
        dispatch(apiError(error.message));
    } finally {
        dispatch(apiEnd());
    }
};

export const getSiteCards = ({ siteId }) => async (dispatch) => {
    dispatch(apiStart());
    try {
        const res = await axios.get(`${apiConfig.domain}${apiConfig.version}/GetSiteCardsBySiteId`, { params: { SiteId: siteId } });
        dispatch({ type: GET_SITE_CARDS, payload: res.data });
    } catch (error) {
        dispatch(apiError(error.message));
    } finally {
        dispatch(apiEnd());
    }
};

export const getSiteUsers = ({ siteId }) => async (dispatch) => {
    dispatch(apiStart());
    try {
        const res = await axios.get(`${apiConfig.domain}${apiConfig.version}/GetSiteUsersBySiteId`, { params: { SiteId: siteId } });
        dispatch({ type: GET_SITE_USERS, payload: res.data });
    } catch (error) {
        dispatch(setSnackbar('error', 'Unable to retrieve site user info'));
        dispatch(apiError(error.message));
    } finally {
        dispatch(apiEnd());
    }
};

export const createSite = (site) => async (dispatch) => {
    dispatch(apiStart());
    try {
        const res = await axios.post(`${apiConfig.domain}${apiConfig.version}/CreateSite`, site);
        handleApiResponse(res, dispatch, { type: CREATE_SITE, payload: res.data }, 'Project created successfully', 'Failed to create site');
        if (res.status === 200) {
            dispatch(getAllSitesByOrgId(site.OrgId));
        }
    } catch (error) {
        dispatch(setSnackbar('error', 'Failed to create site'));
        dispatch(apiError(error.message));
    } finally {
        dispatch(apiEnd());
    }
};

export const updateSite = ({ id: siteId, siteName, siteDescription, isActive, isArchive, isDemo }, orgId) => async (dispatch) => {
    dispatch(apiStart());
    const site = { siteName, siteDescription, isActive, isArchive, isDemo };
    try {
        const res = await axios.put(`${apiConfig.domain}${apiConfig.version}/UpdateSite`, site, { params: { SiteId: siteId } });
        handleApiResponse(res, dispatch, { type: UPDATE_SITE, payload: res.data }, 'Project updated successfully', 'Failed to update site');
        if (res.status === 200) {
            orgId ? dispatch(getAllSitesByOrgId(orgId)) : dispatch(getSiteList()) && dispatch(getSite(siteId));
        }
    } catch (error) {
        dispatch(setSnackbar('error', 'Failed to update site'));
        dispatch(apiError(error.message));
    } finally {
        dispatch(apiEnd());
    }
};

export const deleteSite = (site) => async (dispatch) => {
    dispatch(apiStart());
    try {
        const res = await axios.delete(`${apiConfig.domain}${apiConfig.version}/DeleteSite`, { params: { SiteId: site.siteId } });
        handleApiResponse(res, dispatch, { type: DELETE_SITE, payload: res.data }, null, 'Unable to delete site');
        if (res.status === 200) {
            dispatch(getSiteList());
        }
    } catch (error) {
        dispatch(setSnackbar('error', 'Unable to delete site'));
        dispatch(apiError(error.message));
    } finally {
        dispatch(apiEnd());
    }
};

export const inviteUserToSite = (siteId, userInput) => async (dispatch) => {
    dispatch(apiStart());
    try {
        const res = await axios.post(`${apiConfig.domain}${apiConfig.version}/InviteUserToSite`, { Users: userInput });
        handleApiResponse(res, dispatch, getSiteUsers({ siteId }), 'User added to site', 'Failed to update user role');
    } catch (error) {
        dispatch(setSnackbar('error', `Failed to Add User to site`));
        dispatch(apiError(error.message));
    } finally {
        dispatch(apiEnd());
    }
};

export const updateSiteImage = (photo, siteId) => async (dispatch) => {
    dispatch(apiStart());
    try {
        const formData = new FormData();
        formData.append('file', photo);
        const res = await axios.post(`${apiConfig.domain}${apiConfig.version}/UploadProjectPhoto`, formData, { params: { siteId } });
        dispatch({ type: UPDATE_SITE_IMAGE, payload: res.data });
        dispatch(setSnackbar('success', 'Image uploaded successfully'));
        dispatch(getSite(siteId));
    } catch (error) {
        dispatch(setSnackbar('error', 'Failed to upload image'));
        dispatch(apiError(error.message));
    } finally {
        dispatch(apiEnd());
    }
};

export const clearSite = () => (dispatch) => {
    dispatch({ type: CLEAR_SITE });
};

export const clearSiteUsers = () => (dispatch) => {
    dispatch({ type: GET_SITE_USERS, payload: [] });
};
