const initialState = {
    open: false,
    message: '',
    status: 'info'
}


const snackbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SNACKBAR':
            return {
                open: true,
                message: action.payload.message,
                status: action.payload.status
            }
        case 'CLEAR_SNACKBAR':
            return initialState
        default:
            return state
    }
}

export default snackbarReducer