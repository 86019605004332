const initialState = {
    isLoading: true,
    isAuthenticated: false,
    user: null,
    error: null
};

const ActionTypes = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    LOGOUT: 'LOGOUT'
};

const firebaseReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOGIN_REQUEST:
            return { ...state, isLoading: true };
        case ActionTypes.LOGIN_SUCCESS:
            return { ...state, isLoading: false, isAuthenticated: true, user: action.payload };
        case ActionTypes.LOGIN_FAILURE:
            return { ...state, isLoading: false, error: action.payload };
        case ActionTypes.LOGOUT:
            return { ...initialState, isLoading: false };
        default:
            return state;
    }
}

export default firebaseReducer