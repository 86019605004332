import AuthGuard from "./auth/AuthGuard";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import dashboardRoutes from './views/Argyle/dashboard/DashboardRoutes';
import adminRoutes from './views/Argyle/admin/AdminRoutes';
import userRoutes from './views/Argyle/user/UserRoutes';
import siteRoutes from './views/Argyle/sites/SiteRoutes';
import orgRoutes from './views/Argyle/orgs/OrgRoutes';
import sessionRoutes from "./views/Argyle/sessions/SessionRoutes";

const routes = [
  {
    element: (
      <AuthGuard routes={[
        ...dashboardRoutes,
        ...adminRoutes,
        ...userRoutes,
        ...orgRoutes,
        ...siteRoutes,
      ]}>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...adminRoutes,
      ...userRoutes,
      ...orgRoutes,
      ...siteRoutes,
    ]
  },
  // session pages route
  ...sessionRoutes
];

export default routes;
