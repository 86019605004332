const getUserRoleAuthStatus = (pathname, user, routes) => {
  const matched = routes.find((r) => r.path === pathname);

  if (matched && matched.auth && matched.auth.length) {
    const allowedRoles = matched.auth;
    return allowedRoles.includes(user.userRole);
  }
  return true;
};

export default getUserRoleAuthStatus;
