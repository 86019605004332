import {
  GET_CARD,
  DELETE_CARD
} from '../actions/CardActions';

const initialState = {
  card: {},
}

const CardReducer = function (state = initialState, action) {
  switch (action.type) {
      case GET_CARD: {
          return {
              ...state,
              card: {...action.payload},
          }
      }
      case DELETE_CARD: {
        return {
            ...state,
            card: {...action.payload},
        }
      }
      default: {
          return {
              ...state,
          }
      }
  }
}

export default CardReducer
