import { lazy } from 'react';
import Loadable from 'app/components/Loadable';

// Lazy loading with Loadable for each component
const NotFound = Loadable(lazy(() => import('./NotFound')));
const ForgotPassword = Loadable(lazy(() => import('./ForgotPassword')));
const FirebaseLogin = Loadable(lazy(() => import('./FirebaseLogin')));
const SetPassword = Loadable(lazy(() => import('./SetPassword')));
const FirebaseRegister = Loadable(lazy(() => import('./FirebaseRegister')));
const ResetPassword = Loadable(lazy(() => import('./ResetPassword')));

// Updated sessionRoutes array with 'element' instead of 'component'
const sessionRoutes = [
    {
        path: '/session/signin',
        element: <FirebaseLogin />,
    },
    {
        path: '/session/forgot-password',
        element: <ForgotPassword />,
    },
    {
        path: '/session/set-password',
        element: <SetPassword />,
    },
    {
        path: '/session/404',
        element: <NotFound />,
    },
    {
        path: '/session/signup',
        element: <FirebaseRegister />,
    },
    {
        path: '/session/reset-password',
        element: <ResetPassword />
    }
];

export default sessionRoutes;
