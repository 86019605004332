
import { setSnackbar } from './SnackbarActions'

import axios from 'axios';

const apiConfig = {
    domain: process.env.REACT_APP_API_DOMAIN,
    version: process.env.REACT_APP_API_VERSION
};

// Action Types

export const GET_ALL_LICENSES_FAILURE = 'GET_ALL_LICENSES_FAILURE';
export const GET_ALL_LICENSES_SUCCESS = 'GET_ALL_LICENSES_SUCCESS';
export const DELETE_LICENSE_FAILURE = 'DELETE_LICENSE_FAILURE';
export const DELETE_LICENSE_SUCCESS = 'DELETE_LICENSE_SUCCESS';
export const REQUEST_LICENSE_FAILURE = 'REQUEST_LICENSE_FAILURE';
export const REQUEST_LICENSE_SUCCESS = 'REQUEST_LICENSE_SUCCESS';



export const getAllLicensesByOrgID = (orgId) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`${apiConfig.domain}${apiConfig.version}/GetAllLicensesByOrgID`, { params: { orgId } });
            dispatch({ type: GET_ALL_LICENSES_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_ALL_LICENSES_FAILURE, payload: error.message });
        }
    };
};

export const clearLicenses = () => {
    return (dispatch) => {
        dispatch({ type: GET_ALL_LICENSES_SUCCESS, payload: [] });
    };
};


//Org Owner or manager can release licenses from the organizations devices
export const deleteLicense = (licenseId, orgId) => {
    return async (dispatch) => {
        try {
            const response = await axios.delete(`${apiConfig.domain}${apiConfig.version}/deleteLicense`, { params: { licenseId, orgId } });
            dispatch({ type: DELETE_LICENSE_SUCCESS, payload: response.data });
            dispatch(getAllLicensesByOrgID(orgId));
            dispatch(setSnackbar('success', "License released successfully"))

        } catch (error) {
            console.error(error);
            dispatch({ type: GET_ALL_LICENSES_FAILURE, payload: error.message });
            dispatch(setSnackbar('error', `Received status code ${error.message}`))
        }
    };
}

export const requestHelp = (request) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(`${apiConfig.domain}${apiConfig.version}/zapierHelpRequest`, { request });
            dispatch({ type: REQUEST_LICENSE_SUCCESS, payload: response.data });
            dispatch(setSnackbar('success', "Help request sent to our team successfully, you will receive an email once it is processed."))
        } catch (error) {
            console.error(error);
            dispatch({ type: REQUEST_LICENSE_FAILURE, payload: error.message });
            dispatch(setSnackbar('error', `Received status code ${error.message}`))
        }
    };
}


