import axios from 'axios'

const apiConfig = {
    domain: process.env.REACT_APP_API_DOMAIN,
    version: process.env.REACT_APP_API_VERSION
};

export const GET_ALL_QR_DATA = 'GET_ALL_QR_DATA'
export const CREATE_QR_DATA = 'CREATE_QR_DATA'
export const DELETE_QR_DATA = 'DELETE_QR_DATA'
export const UPDATE_QR_DATA = 'UPDATE_QR_DATA'
export const GET_ALL_QR_ERROR = 'GET_ALL_QR_ERROR'
export const QR_DATA_GENERATED = 'QR_DATA_GENERATED'
export const DOWNLOAD_FILE_ERROR = 'DOWNLOAD_FILE_ERROR'

export const CreateQRData = ({ qrData, siteId }) => (dispatch) => {
    const url = `${apiConfig.domain}${apiConfig.version}/CreateQRData`;

    axios.post(url, { QRData: qrData })
        .then((res) => {
            dispatch({
                type: CREATE_QR_DATA,
                payload: res.data,
            });
            dispatch(GetAllQRBySiteId({ siteId: siteId }));
        })
        .catch((err) => {
            dispatch({
                type: GET_ALL_QR_ERROR,
                payload: err.response ? err.response.data.message : 'Network error. Please try again.',
            });
        });
};

export const GetAllQRBySiteId = ({ siteId }) => (dispatch) => {
    const url = `${apiConfig.domain}${apiConfig.version}/GetAllQRBySiteId`;
    axios.get(url, {
        params: {
            SiteId: siteId,
        }
    })
        .then((res) => {
            if (res.data.data && Array.isArray(res.data.data)) { // Check if the response contains data and it's an array
                dispatch({
                    type: GET_ALL_QR_DATA,
                    payload: res.data.data,
                });
            } else {
                dispatch({
                    type: GET_ALL_QR_ERROR,
                    payload: 'Received unexpected data from the server.',
                });
            }
        })
        .catch((err) => {
            dispatch({
                type: GET_ALL_QR_ERROR,
                payload: err.response ? err.response.data.message : 'Network error. Please try again.',
            });
        });
}

export const DeleteQRData = ({ siteId, qrData }) => (dispatch) => {
    const url = `${apiConfig.domain}${apiConfig.version}/DeleteQRData`;

    axios.put(url, { QRData: qrData }, { params: { SiteId: siteId } })
        .then((res) => {
            // Assuming res.data contains the deleted QR data's QRID
            dispatch({
                type: DELETE_QR_DATA,
                payload: res.data.QRID,  // Sending only the QRID as payload
            });
            dispatch(GetAllQRBySiteId({ siteId: siteId }));
        })
        .catch((err) => {
            dispatch({
                type: GET_ALL_QR_ERROR,
                payload: err.response ? err.response.data.message : 'Network error. Please try again.',
            });
        });
};

export const UpdateQRData = ({ siteId, qrData }) => (dispatch) => {
    const url = `${apiConfig.domain}${apiConfig.version}/UpdateQRData`;

    axios.put(url, { QRData: qrData }, { params: { SiteId: siteId } })
        .then((res) => {
            dispatch({
                type: UPDATE_QR_DATA,
                payload: res.data,
            });
            dispatch(GetAllQRBySiteId({ siteId: siteId }));
        })
        .catch((err) => {
            dispatch({
                type: GET_ALL_QR_ERROR,
                payload: err.response ? err.response.data.message : 'Network error. Please try again.',
            });
        });
};



export const generateAndDownloadQRImage = ({ siteId, qrData }) => (dispatch) => {
    const downloadUrl = `${apiConfig.domain}${apiConfig.version}/GenerateQRImage`;

    axios.post(downloadUrl, { QRData: qrData }, {
        params: { SiteId: siteId },
        responseType: 'blob',  // Important to handle binary data
    })
        .then((res) => {
            // Convert the response to a blob
            const blob = new Blob([res.data], { type: 'application/pdf' });

            // Create a link element to download the blob as a file
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'QRCodes.pdf';  // Use a fixed or dynamic filename here

            document.body.appendChild(a);
            a.click();

            // Clean up
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            dispatch({
                type: QR_DATA_GENERATED,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_ALL_QR_ERROR,
                payload: err.response ? err.response.data.message : 'Network error. Please try again.',
            });
        });
};


