export const SET_USER_NAVIGATION = 'SET_USER_NAVIGATION'

const getFilteredNavigations = (navList = [], role) => {
    return navList.reduce((filteredArray, navItem) => {
        let newNavItem = { ...navItem };

        if (newNavItem.auth && newNavItem.auth.includes(role)) {
            filteredArray.push(newNavItem);
        } else if (newNavItem.children) {
            newNavItem.children = getFilteredNavigations(newNavItem.children, role);
            if (newNavItem.children.length > 0) {
                filteredArray.push(newNavItem);
            }
        } else if (!newNavItem.auth) {
            filteredArray.push(newNavItem);
        }

        return filteredArray;
    }, []);
};


// const getfilteredNavigations = (navList = [], role) => {
//     return navList.reduce((array, nav) => {
//         if (nav.auth) {
//             if (nav.auth.includes(role)) {
//                 array.push(nav)
//             }
//         } else {
//             if (nav.children) {
//                 nav.children = getfilteredNavigations(nav.children, role)
//                 array.push(nav)
//             } else {
//                 array.push(nav)
//             }
//         }
//         return array
//     }, [])
// }

export function getNavigationByUser() {
    return (dispatch, getState) => {
        let { user, navigations = [] } = getState()

        let filteredNavigations = getFilteredNavigations(navigations, user.role)

        dispatch({
            type: SET_USER_NAVIGATION,
            payload: [...filteredNavigations],
        })
    }
}
