import React, { lazy } from 'react';
import Loadable from 'app/components/Loadable';

const OrgDetails = Loadable(lazy(() => import('./OrgDetails')));
const OrgCreate = Loadable(lazy(() => import('./OrgCreate')));

const orgRoutes = [
  {
    path: '/org/details/:orgId',
    element: <OrgDetails />,
  },
  {
    path: '/org/create',
    element: <OrgCreate />,
  },
];

export default orgRoutes;
