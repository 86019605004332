
import { setSnackbar } from './SnackbarActions'
import axios from 'axios'

const apiConfig = {
    domain: process.env.REACT_APP_API_DOMAIN,
    version: process.env.REACT_APP_API_VERSION
};


export const GET_ALL_FILES = 'GET_ALL_FILES'
export const UPLOAD_FILES = 'UPLOAD_FILES'
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_ERROR = 'DOWNLOAD_FILE_ERROR';
export const GET_ALL_FILES_ERROR = 'GET_ALL_FILES_ERROR';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_ERROR = 'DELETE_FILE_ERROR';

export const deleteFile = ({ fileName, siteId }) => (dispatch) => {
    const deleteUrl = `${apiConfig.domain}${apiConfig.version}/DeleteFilebyName`;

    axios.delete(deleteUrl, {
        params: {
            FileName: fileName,
            SiteId: siteId,
        }
    })
        .then((res) => {
            dispatch({
                type: DELETE_FILE_SUCCESS,
                payload: fileName,
            });
            dispatch(GetAllFilesForSite({ siteId: siteId }));
        })
        .catch((err) => {
            console.error('Error deleting the file:', err);
            dispatch({
                type: DELETE_FILE_ERROR,
                payload: err.response ? err.response.data.message : 'Network error. Please try again.',
            });
        });
}

export const downloadFile = ({ filename, siteId }) => (dispatch) => {
    const downloadUrl = `${apiConfig.domain}${apiConfig.version}/DownloadFileByName`;

    axios.get(downloadUrl, {
        params: {
            FileName: filename,
            SiteId: siteId,
        },
        responseType: 'blob', // Important to handle binary data
    })
        .then((res) => {
            // Convert the response to a blob
            const blob = new Blob([res.data], { type: 'application/octet-stream' });

            // Create a link element to download the blob as a file
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = filename;

            document.body.appendChild(a);
            a.click();

            // Clean up
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            dispatch({
                type: DOWNLOAD_FILE_SUCCESS,
                payload: filename,
            });
        })
        .catch((err) => {
            console.error('Error downloading the file:', err);
            dispatch({
                type: DOWNLOAD_FILE_ERROR,
                payload: err.response ? err.response.data.message : 'Network error. Please try again.',
            });
        });
}

export const GetAllFilesForSite = ({ siteId }) => (dispatch) => {
    return axios
        .get(`${apiConfig.domain}${apiConfig.version}/GetAllFilesForSite`, {
            params: { SiteId: siteId },
        })
        .then((res) => {
            if (res.data && Array.isArray(res.data)) {
                dispatch({
                    type: GET_ALL_FILES,
                    payload: res.data,
                });
                return res.data; // Return data for promise resolution
            } else {
                console.error('Unexpected response structure:', res);
                dispatch({
                    type: GET_ALL_FILES_ERROR,
                    payload: 'Received unexpected data from the server.',
                });
                throw new Error('Unexpected data structure'); // Reject the promise
            }
        })
        .catch((err) => {
            console.error('Error fetching files:', err);
            dispatch({
                type: GET_ALL_FILES_ERROR,
                payload: err.response ? err.response.data.message : 'Network error. Please try again.',
            });
            throw err; // Reject the promise with the error
        });
};


export const UploadFileToSite = (siteId, files) => async (dispatch) => {
    const formData = new FormData();
    files.forEach((file, index) => {
        formData.append(`file${index}`, file.file);
    });
    formData.append('SiteId', siteId);

    try {
        const res = await axios.post(
            `${apiConfig.domain}${apiConfig.version}/UploadMultiFiles`,
            formData
        );

        if (res.status === 200) {
            dispatch({
                type: UPLOAD_FILES,
                payload: res.data,
            })
            dispatch(GetAllFilesForSite({ siteId: siteId }));

            // alert('File Uploaded successfully')
        } else {
            // Handle other status codes here
            console.error(`Received status code ${res.status}`)
        }
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error(error.response.data)
            console.error(error.response.status)
            console.error(error.response.headers)
        } else if (error.request) {
            // The request was made but no response was received
            console.error(error.request)
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error', error.message)
        }
        dispatch(setSnackbar('error', 'Unable to Upload files to site'))
    }
    return null;
}