import { red } from '@mui/material/colors';
import { components } from './components';

const themeOptions = {
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    fontSize: 12,
    h1: {
      fontFamily: ['Sora', 'sans-serif'].join(','),
      fontSize: 40,
    },
    h2: {
      fontFamily: ['Sora', 'sans-serif'].join(','),
      fontSize: 32,
    },
    h3: {
      fontFamily: ['Sora', 'sans-serif'].join(','),
      fontSize: 24,
    },
    h4: {
      fontFamily: ['Sora', 'sans-serif'].join(','),
      fontSize: 20,
    },
    h5: {
      fontFamily: ['Sora', 'sans-serif'].join(','),
      fontSize: 16,
    },
    h6: {
      fontFamily: ['Sora', 'sans-serif'].join(','),
      fontSize: 14,
    },
    body: {
      fontFamily: ['Inter-bold', 'sans-serif'].join(','),
      fontSize: 12,
    },
    p: {
      fontFamily: ['Inter-medium', 'sans-serif'].join(','),
      fontSize: 12,
    },
    button: {
      fontFamily: ['IBM Plex Mono', 'monospace'].join(','),
      fontSize: 12,
    },
  },

  status: { danger: red[500] },
  components: { ...components },
};

export default themeOptions;
