import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import useAuth from 'app/hooks/useAuth';
import getUserRoleAuthStatus from 'app/auth/authRoleStatus';

const AuthGuard = ({ children, routes }) => {
  const { user, isAuthenticated } = useAuth();
  const { pathname } = useLocation();

  const isUserRoleAuthenticated = user
    ? getUserRoleAuthStatus(pathname, user, routes)
    : false;

  if (isAuthenticated && isUserRoleAuthenticated) {
    return <>{children}</>;
  } else {
    return <Navigate replace to="/session/signin" state={{ from: pathname }} />;
  }
};

export default AuthGuard;
