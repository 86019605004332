import {
    GET_ALL_QR_DATA,
    CREATE_QR_DATA,
    DELETE_QR_DATA,
    UPDATE_QR_DATA,
    GET_ALL_QR_ERROR,
    QR_DATA_GENERATED

} from '../actions/QRActions';

const initialState = {
    QRData: {},
    QRDataList: [],
    uploadedData: {},
    lastDownloadedQRData: null, // To store the name of the last downloaded qr data
    getFilesError: null, // To store any error messages related to file download

}

const QRReducer = function (state = initialState, action) {
    switch (action.type) {

        case CREATE_QR_DATA: {
            return {
                ...state,
                QRDataList: state.QRDataList.filter(qrData => qrData !== action.payload),
            }
        }
        case GET_ALL_QR_DATA: {
            return {
                ...state,
                QRDataList: [...action.payload],
            }
        }
        case DELETE_QR_DATA: {
            return {
                ...state,
                QRDataList: state.QRDataList.filter(qrData => qrData !== action.payload),
            }
        }
        case UPDATE_QR_DATA: {
            return {
                ...state,
                uploadedData: action.payload,
            }
        }
        case GET_ALL_QR_ERROR: {
            return {
                ...state,
                getFilesError: action.payload, // Store the error message
            }
        }
        case QR_DATA_GENERATED: {
            return {
                ...state,
                lastDownloadedQRData: action.payload,
                downloadError: null,
            }
        }


        default: {
            return state;
        }
    }
}

export default QRReducer;
