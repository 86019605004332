export const setSnackbar = (status, message) => {
    return {
        type: 'SET_SNACKBAR',
        payload: {
            status,
            message
        }
    }
}
export const clearSnackbar = () => {
    return {
        type: 'CLEAR_SNACKBAR'
    }
}