import React, { lazy } from "react";
import Loadable from "app/components/Loadable";
import { authRoles } from "../../../auth/authRoles";

const ManageUsers = Loadable(lazy(() => import("./ManageUsers")));
const ManageSites = Loadable(lazy(() => import("./ManageSites")));
const ManageOrgs = Loadable(lazy(() => import("./ManageOrgs")));
const FirebaseRegister = Loadable(lazy(() => import("../sessions/FirebaseRegister")));

const adminRoutes = [
  {
    path: "/admin/manage-users",
    element: <ManageUsers />,
    auth: authRoles.admin
  },
  {
    path: "/admin/manage-sites",
    element: <ManageSites />,
    auth: authRoles.admin
  },
  {
    path: "/admin/manage-orgs",
    element: <ManageOrgs />,
    auth: authRoles.admin
  },
  {
    path: "/admin/signup",
    element: <FirebaseRegister />,
    auth: authRoles.admin
  }
];

export default adminRoutes;
