import React, { lazy } from 'react';
import Loadable from "app/components/Loadable";
import { authRoles } from '../../../auth/authRoles'
const Dashboard = Loadable(lazy(() => import('./Dashboard')))

const dashboardRoutes = [
    {
        path: '/',
        element: <Dashboard />,
        auth: authRoles.user,
    }
]

export default dashboardRoutes
