import {
    GET_ALL_FILES,
    UPLOAD_FILES,
    DOWNLOAD_FILE_SUCCESS,
    DOWNLOAD_FILE_ERROR,
    GET_ALL_FILES_ERROR,
    DELETE_FILE_SUCCESS,
    DELETE_FILE_ERROR
} from '../actions/FileActions';

const initialState = {
    files: {},
    fileList: [],
    uploadedData: [],
    lastDownloadedFile: null, // To store the name of the last downloaded file
    downloadError: null, // To store any error messages related to file download
    getFilesError: null, // To store any error messages related to fetching files
}

const FileReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_FILES: {
            return {
                ...state,
                fileList: [...action.payload],
            }
        }
        case UPLOAD_FILES: {
            return {
                ...state,
                uploadedData: [...action.payload],
            }
        }
        case DOWNLOAD_FILE_SUCCESS: {
            return {
                ...state,
                lastDownloadedFile: action.payload, // Store the name of the last downloaded file
                downloadError: null, // Clear any previous download errors
            }
        }
        case DOWNLOAD_FILE_ERROR: {
            return {
                ...state,
                downloadError: action.payload, // Store the error message
            }
        }
        case GET_ALL_FILES_ERROR: {
            return {
                ...state,
                getFilesError: action.payload, // Store the error message
            }
        }
        case DELETE_FILE_SUCCESS: {
            return {
                ...state,
                fileList: state.fileList.filter(file => file !== action.payload), // Remove the deleted file from the list
                deleteError: null, // Clear any previous delete errors
            }
        }
        case DELETE_FILE_ERROR: {
            return {
                ...state,
                deleteError: action.payload, // Store the error message
            }
        }
        default: {
            return state;
        }
    }
}

export default FileReducer;
