import { GET_ALL_LICENSES_FAILURE, GET_ALL_LICENSES_SUCCESS, DELETE_LICENSE_FAILURE, DELETE_LICENSE_SUCCESS, REQUEST_LICENSE_FAILURE, REQUEST_LICENSE_SUCCESS } from '../actions/LicenseActions';

const initialState = {
    license: null,
    allLicenses: [],
    loading: false,
    error: null,
    message: null
};

const licenseReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_LICENSES_SUCCESS:
            return {
                ...state,
                allLicenses: action.payload,
                loading: false,
                error: null,
            };
        case GET_ALL_LICENSES_FAILURE:
            return {
                ...state,
                allLicenses: null,
                loading: false,
                error: action.payload,
            };
        case DELETE_LICENSE_SUCCESS:
            return {
                ...state,
                message: action.payload,
                loading: false,
                error: null,
            };
        case DELETE_LICENSE_FAILURE:
            return {
                ...state,
                message: null,
                loading: false,
                error: action.payload,
            };
        case REQUEST_LICENSE_SUCCESS:
            return {
                ...state,
                message: action.payload,
                loading: false,
                error: null,
            };
        case REQUEST_LICENSE_FAILURE:
            return {
                ...state,
                license: null,
                loading: false,
                error: action.payload,
            };


        default:
            return state;
    }
};

export default licenseReducer;
