import React, { lazy } from 'react';
import Loadable from "app/components/Loadable";


const SiteDetails = Loadable(lazy(() => import("./SiteDetails")));
const EditSite = Loadable(lazy(() => import("./EditSite")));
const ManageUsers = Loadable(lazy(() => import("./ManageUsers")));


const siteRoutes = [
    {
        path: '/site/details/:siteId',
        element: <SiteDetails />,
    },
    {
        path: '/site/edit-site',
        element: <EditSite />,
    },
    {
        path: '/site/manage-users',
        element: <ManageUsers />,
    },
]

export default siteRoutes
