import axios from 'axios';
import { setSnackbar } from './SnackbarActions';
import { getSiteList } from './SiteActions';

const apiConfig = {
    domain: process.env.REACT_APP_API_DOMAIN,
    version: process.env.REACT_APP_API_VERSION
};

// Action Types
export const CREATE_ORG = 'CREATE_ORG';
export const GET_ALL_ORGS = 'GET_ALL_ORGS';
export const UPDATE_ORG = 'UPDATE_ORG';
export const DELETE_ORG = 'DELETE_ORG';
export const GET_ORG_BY_ID = 'GET_ORG_BY_ID';
export const GET_ALL_SITES_BY_ORG_ID = 'GET_ALL_SITES_BY_ORG_ID';
export const ADD_SITE_TO_ORG = 'ADD_SITE_TO_ORG';
export const GET_ALL_USERS_BY_ORG_ID = 'GET_ALL_USERS_BY_ORG_ID';
export const CLEAR_ORG_USER_LIST = 'CLEAR_ORG_USER_LIST';
export const CLEAR_ORG = 'CLEAR_ORG';
export const API_START = 'API_START';
export const API_END = 'API_END';
export const API_ERROR = 'API_ERROR';

const apiStart = () => ({ type: API_START });
const apiEnd = () => ({ type: API_END });
const apiError = (error) => ({ type: API_ERROR, payload: error });

export const createOrg = (orgData) => async dispatch => {
    dispatch(apiStart());
    const url = `${apiConfig.domain}${apiConfig.version}/CreateOrg`;
    try {
        const response = await axios.post(url, orgData);

        dispatch({
            type: CREATE_ORG,
            payload: response.data
        });
        dispatch(setSnackbar('success', 'Organization created successfully'));
    } catch (error) {
        console.error(error);
        dispatch(apiError(error.message));
        dispatch(setSnackbar('error', `Received status code ${error.message}`));
    } finally {
        dispatch(apiEnd());
    }
};

export const getAllOrgs = () => async dispatch => {
    dispatch(apiStart());
    const url = `${apiConfig.domain}${apiConfig.version}/GetAllOrgs`;
    try {
        const response = await axios.get(url);

        dispatch({
            type: GET_ALL_ORGS,
            payload: response.data
        });
    } catch (error) {
        console.error(error);
        dispatch(apiError(error.message));
    } finally {
        dispatch(apiEnd());
    }
};

export const updateOrg = (orgId, updatedOrgData) => async dispatch => {
    dispatch(apiStart());
    const url = `${apiConfig.domain}${apiConfig.version}/UpdateOrg`;
    try {
        const response = await axios.put(url, updatedOrgData, { params: { orgId } });

        dispatch({
            type: UPDATE_ORG,
            payload: {
                orgId,
                updatedOrgData: response.data
            }
        });
        dispatch(setSnackbar('success', 'Organization updated successfully'));
        dispatch(getAllOrgs());
        dispatch(getOrgById(orgId));
    } catch (error) {
        dispatch(apiError(error.message));
        dispatch(setSnackbar('error', `Received status code ${error.message}`));
    } finally {
        dispatch(apiEnd());
    }
};

export const deleteOrg = (orgId) => async dispatch => {
    dispatch(apiStart());
    const url = `${apiConfig.domain}${apiConfig.version}/DeleteOrg`;
    try {
        await axios.delete(url, { params: { orgId } });
        dispatch({
            type: DELETE_ORG,
            payload: orgId
        });
        dispatch(setSnackbar('success', 'Organization is now inactive'));
        dispatch(getAllOrgs());
    } catch (error) {
        console.error(error);
        dispatch(apiError(error.message));
        dispatch(setSnackbar('error', `Received status code ${error.message}`));
    } finally {
        dispatch(apiEnd());
    }
};

export const getOrgById = (orgId) => async dispatch => {
    dispatch(apiStart());
    const url = `${apiConfig.domain}${apiConfig.version}/GetOrgById`;
    try {
        const response = await axios.get(url, { params: { orgId } });

        dispatch({
            type: GET_ORG_BY_ID,
            payload: response.data
        });
    } catch (error) {
        console.error(error);
        dispatch(apiError(error.message));
    } finally {
        dispatch(apiEnd());
    }
};

export const getAllSitesByOrgId = (orgId) => async dispatch => {
    dispatch(apiStart());
    const url = `${apiConfig.domain}${apiConfig.version}/GetAllSitesByOrgId`;
    try {
        const response = await axios.get(url, { params: { orgId } });

        dispatch({
            type: GET_ALL_SITES_BY_ORG_ID,
            payload: response.data
        });
    } catch (error) {
        console.error(error);
        dispatch(apiError(error.message));
    } finally {
        dispatch(apiEnd());
    }
}

export const addSiteToOrg = (orgId, siteId) => async dispatch => {
    dispatch(apiStart());
    const url = `${apiConfig.domain}${apiConfig.version}/AddSiteToOrg`;
    try {
        const response = await axios.post(url, null, { params: { OrgId: orgId, SiteId: siteId } });

        dispatch({
            type: ADD_SITE_TO_ORG,
            payload: response.data
        });
        dispatch(setSnackbar('success', response.data.message));
        dispatch(getSiteList());
        dispatch(getAllOrgs());
    } catch (error) {
        console.error(error);
        dispatch(apiError(error.message));
        dispatch(setSnackbar('error', `Received status code ${error.message}`));
    } finally {
        dispatch(apiEnd());
    }
};

export const getAllUsersByOrgId = (orgId) => async dispatch => {
    dispatch(apiStart());
    const url = `${apiConfig.domain}${apiConfig.version}/GetAllUsersByOrgId`;
    try {
        const response = await axios.get(url, { params: { orgId } });

        dispatch({
            type: GET_ALL_USERS_BY_ORG_ID,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
        dispatch(apiError(error.message));
    } finally {
        dispatch(apiEnd());
    }
};

export const clearOrgUserList = () => (dispatch) => {
    dispatch({
        type: CLEAR_ORG_USER_LIST,
    });
};

export const clearOrg = () => (dispatch) => {
    dispatch({
        type: CLEAR_ORG,
    });
};

export const updateOrgImage = (image, orgId) => async dispatch => {
    dispatch(apiStart());
    const url = `${apiConfig.domain}${apiConfig.version}/UploadOrgPhoto`;
    try {
        const formData = new FormData();
        formData.append('file', image);
        const response = await axios.post(url, formData, { params: { orgId } });

        response.status ? dispatch(setSnackbar('success', 'Organization image updated successfully')) : dispatch(setSnackbar('error', 'Failed to update organization image'));
        dispatch(getOrgById(orgId));
    } catch (error) {
        console.error(error);
        dispatch(apiError(error.message));
        dispatch(setSnackbar('error', `Received status code ${error.message}`));
    } finally {
        dispatch(apiEnd());
    }
};
