import { differenceInSeconds, format } from "date-fns";


export const convertHexToRGB = (hex) => {
  // check if it's a rgba
  if (hex.match("rgba")) {
    let triplet = hex.slice(5).split(",").slice(0, -1).join(",");
    return triplet;
  }

  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");

    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",");
  }
};

function currentYPosition(elm) {
  if (!window && !elm) return;

  if (elm) return elm.scrollTop;
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(elm) {
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}

export function scrollTo(scrollableElement, elmID) {
  var elm = document.getElementById(elmID);

  if (!elmID || !elm) {
    return;
  }

  var startY = currentYPosition(scrollableElement);
  var stopY = elmYPosition(elm);

  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    scrollTo(0, stopY);
    return;
  }
  var speed = Math.round(distance / 50);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for (var i = startY; i < stopY; i += step) {
      setTimeout(
        (function (leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY);
          };
        })(leapY),
        timer * speed
      );
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function (leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY);
        };
      })(leapY),
      timer * speed
    );
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }
  return false;
}

export function getTimeDifference(date) {
  let difference = differenceInSeconds(new Date(), date);

  if (difference < 60) return `${Math.floor(difference)} sec`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} min`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} h`;
  else if (difference < 86400 * 30) return `${Math.floor(difference / 86400)} d`;
  else if (difference < 86400 * 30 * 12) return `${Math.floor(difference / 86400 / 30)} mon`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} y`;
}

export function isMobile() {
  if (window) {
    return window.matchMedia(`(max-width: 767px)`).matches
  }
  return false
}

export const sanitizeName = (name) => {
  return name.replace(/[^a-zA-Z0-9\s]/g, '');
};

/**
 * Sanitizes an email by stripping out any non-alphanumeric characters except for @ and . characters.
 * @param {string} email - The email to sanitize.
 * @return {string} - The sanitized email.
 */
export const sanitizeEmail = (email) => {
  return email.replace(/[^a-zA-Z0-9@.]/g, '');
};

/**
 * Validates if a given value is a valid email address.
 * @param {string} email - The email to validate.
 * @return {boolean} - Returns true if the email is valid, otherwise false.
 */
export const validateEmail = (email) => {
  // Begins with one or more characters before the @ symbol.
  // Contains a domain with two or more characters after the @ symbol.
  // Has at least one dot in the domain part with at least one character before and after it.
  // It does not allow special characters that are typically not used in email addresses.
  const regexPattern =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regexPattern.test(email)
};

/**
 * Validates if a given value is a float or an empty string.
 * @param {string} value - The value to validate.
 * @return {boolean} - Returns true if the value is a valid float or an empty string, otherwise false.
 */
export const validateFloat = (value) => {
  return /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(value) || value === '';
};


export const determineHighestPrivilege = (UserRoles) => {
  // Define the hierarchy
  const roleHierarchy = {
    Admin: 5,
    Owner: 4,
    Manager: 3,
    Member: 2,
    Guest: 1,
  }

  // Check and assign role values
  let siteRoleValue = roleHierarchy[UserRoles.siteRole] || 0
  let orgRoleValue = roleHierarchy[UserRoles.orgRole] || 0
  let userRoleValue = UserRoles.userRole === 'Admin' ? 5 : 0 // Only Admin has a value

  // Determine the highest role value
  let highestRoleValue = Math.max(
    siteRoleValue,
    orgRoleValue,
    userRoleValue
  )

  return highestRoleValue
}

export const formatDate = (dateString) => {
  return dateString
    ? format(new Date(dateString), 'MM/dd/yyyy')
    : 'No updates...';
};

export const DownloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType })
  const a = document.createElement('a')
  a.download = fileName
  a.href = window.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}


export const formatFileSize = (sizeInBytes) => {
  const units = ["B", "KB", "MB", "GB", "TB"];
  let unitIndex = 0;

  while (sizeInBytes >= 1024 && unitIndex < units.length - 1) {
    sizeInBytes /= 1024;
    unitIndex++;
  }

  return `${sizeInBytes.toFixed(2)} ${units[unitIndex]}`;
}