import { setSnackbar } from './SnackbarActions'
import axios from 'axios'

const apiConfig = {
    domain: process.env.REACT_APP_API_DOMAIN,
    version: process.env.REACT_APP_API_VERSION
};



export const GET_CARD = 'GET_CARD'
export const DELETE_CARD = 'DELETE_CARD'

export const getCard = ({ cardId }) => (dispatch) => {
    axios.get(apiConfig.domain + apiConfig.version + '/GetCardById',
        {
            params: { Id: cardId },
        })
        .then((res) => {
            dispatch({
                type: GET_CARD,
                payload: res.data,
            })
        })
        .catch((error) => {
            dispatch(setSnackbar('error', "Unable to retrieve card"))
            console.log(error)
        })
}

export const deleteCard = ({ cardId }) => (dispatch) => {
    axios.delete(apiConfig.domain + apiConfig.version + '/DeleteCard',
        {
            params: { CardId: cardId },
        })
        .then((res) => {
            dispatch({
                type: DELETE_CARD,
                payload: res.data,
            })
            dispatch(setSnackbar('success', 'Card deleted successfully'))

        })
        .catch((error) => {
            dispatch(setSnackbar('error', "Unable to delete card"))
            console.log(error)
        })
}
